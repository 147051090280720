import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { llama32 } from './utils';

function App() {
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setError(null);
      try {
        const messages = [{ role: "user", content: "Hello!, Who are you? e porque o ceu é azul?" }];
        const response = await llama32(messages);
        setApiResponse(response);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Start <code>Pura Vida IA</code> Wave forecast and take care of your health.
        </p>
        <a
          className="App-link"
          href="https://kaosteck.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn Pura Vida IA
        </a>
        <div style={{ marginTop: '20px', maxWidth: '600px' }}>
          <h2>Resposta da API</h2>
          {loading && <p>Carregando...</p>}
          {error && <p>Erro: {error}</p>}
          {apiResponse && (
            <div
              style={{
                maxHeight: expanded ? 'auto' : '200px', // Define uma altura máxima
                overflowY: 'auto', // Adiciona rolagem se necessário
                border: '1px solid #ddd',
                padding: '10px',
                borderRadius: '4px',
                backgroundColor: '#282c34',
                whiteSpace: 'pre-wrap', // Quebra linhas longas
                wordWrap: 'break-word', // Garante quebra de palavras longas
                textAlign: 'left'
              }}
            >
              {expanded ? apiResponse : `${apiResponse.substring(0, 3000)}...`}
              {/* Botão para expandir/recolher */}
              <button
                onClick={toggleExpanded}
                style={{
                  display: 'block',
                  marginTop: '10px',
                  backgroundColor: '#61dafb',
                  border: 'none',
                  color: 'white',
                  padding: '5px 10px',
                  borderRadius: '3px',
                  cursor: 'pointer'
                }}
              >
                {expanded ? 'Ver menos' : 'Ver mais'}
              </button>
            </div>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
